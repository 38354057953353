@import "../../color.scss";

.form-card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 10px;
    width: 50vw;
    background-color: $card-bg-color;
    border-radius: 5px;
    box-shadow: 2px 2px 5px 2px rgba(0, 0, 0, 0.2);

    font-size: 20;

    .first-row {
        display: flex;
        flex-direction: row;
        width: 90%;
        height: fit-content;
        margin: 5px;

        .form-name {
            font-size: 24px;
            width: 70%;
            border: 1px solid $card-accent-color;
            border-radius: 5px;
            margin-right: 10px;
        }

        .form-color {
            width: 50px;
            height: 50px;
            border: 1px solid $card-accent-color;
            border-radius: 5px;
        }
    }

    .second-row {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 90%;
        margin: 5px;

        .stock-container {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            text-align: center;
            color: white;
            font-weight: 500;
            height: 50px;
            background-color: $card-accent-color;
            border-radius: 5px;
            width: 40%;
            padding-left: 3px;

            .form-stock {
                height: 30px;
                margin: 5px;
                margin-left: 5%;
                width: inherit;
                border: none;
                border-radius: 5px;
            }
        }

        .chances-container {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            text-align: center;
            color: white;
            font-weight: 500;
            height: 50px;
            background-color: $card-accent-color;
            border-radius: 5px;
            width: 50%;
            padding-left: 3px;

            .form-chances{
                height: 30px;
                margin: 5px;
                margin-left: 5%;
                width: 30%;
                border: none;
                border-radius: 5px;
            }
        }
    }

    .third-row {
        display: flex;
        flex-direction: row;
        width: 90%;
        margin: 5px;

        .form-image-select {
            width: 100%;
            border-radius: 5px;
            border: none;
            font-size: 20px;
        }
    }

    .fourth-row {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width: 90%;
        margin: 5px;
        border: 3px solid $card-accent-color;
        border-radius: 5px;

        .form-picto-label {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;

            .form-picto {
                display: none;
            }

            .current-picto {
                max-height: 70px;
            }
        }
    }
}

@media only screen and (max-width: 600px) {
    .form-card {
        width: 95vw;
    }
}