@import '../../color.scss';

:root {
  font-family: Inter, system-ui, Avenir, Helvetica, Arial, sans-serif;
  line-height: 1.5;
  font-weight: 400;

  color-scheme: light dark;
  color: rgba(255, 255, 255, 0.87);
  background-color: #242424;

  font-synthesis: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.appback-bg-color {
  background: $bg-color;
}

.appback-header {
  background-color: $accent-color;
  display: flex;
  flex-direction: row;
  height: 15vh;
  box-shadow: 2px 2px 5px 2px rgba(0, 0, 0, 0.2);

  .header-button {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    background-color: $accent-color;
    font-family: inherit;
    font-size: 32;
    font-weight: bolder;
    border: none;
    justify-content: center;
    align-items: center;

    img {
      max-width: 50px;
      max-height: 50px;
    }

    &:hover {
      background-color: darken($accent-color, 10%);
      cursor: pointer;
    }
  }
}

.cards-container {
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 10px;

  .form-card-container {
    position: relative;

    .delete-button {
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: $accent-color;
      width: 40px;
      height: 40px;
      border: none;
      border-radius: 20px;
      box-shadow: 2px 2px 5px 2px rgba(0, 0, 0, 0.2);

      position: absolute;
      top: 0;
      right: 0;

      &:hover {
        background-color: darken($accent-color, 10%);
        cursor: pointer;
      }

      img {
        height: inherit;
        width: inherit;
      }
    }
  }
}

.add-button {
  background-color: $accent-color;
  height: 70px;
  width: 70px;
  font-size: larger;
  font-weight: 900;
  border: none;
  border-radius: 35px;
  position: fixed;
  bottom: 20px;
  right: 20px;
  box-shadow: 2px 2px 5px 2px rgba(0, 0, 0, 0.2);
  z-index: 2;

  &:hover {
    background-color: darken($accent-color, 10%);
    cursor: pointer;
  }
}

@media only screen and (max-width: 600px) {
  .cards-container {
    margin-left: 0;
    margin-right: 0;
  }
}